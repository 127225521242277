import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { BrowserView } from 'react-device-detect'
import Link from '../../components/CustomLink'
import Layout from '../../components/Layout'
import TagItem from '../../components/TagItem'
import { ArrowBackIcon } from '../../img/icons'

export const TagsPageTemplate = ({ group, helmet }) => {
  return (
    <section className="p-6 md:p-4 md:pt-8">
      {helmet || ''}
      <BrowserView>
        <Link to="/blog" className="pt-4 block">
          <ArrowBackIcon />
          <span className="ml-1">Back to blog</span>
        </Link>
      </BrowserView>
      <h3 className="text-lg font-medium pt-4">List of tags</h3>

      <ul className="py-2 flex flex-wrap">
        {group.map(tag => (
          <li className="py-2" key={tag.fieldValue}>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className="no-underline">
              <TagItem text={`${tag.fieldValue} (${tag.totalCount})`} />
            </Link>
          </li>
        ))}
      </ul>
    </section>
  )
}

TagsPageTemplate.propTypes = {
  group: PropTypes.array,
  helmet: PropTypes.object,
}

class TagsPage extends React.Component {
  render() {
    const { group } = this.props.data.allMarkdownRemark
    const { title } = this.props.data.site.siteMetadata

    return (
      <Layout>
        <TagsPageTemplate group={group} helmet={<Helmet title={`Tags | ${title}`} />} />
      </Layout>
    )
  }
}

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000, filter: { frontmatter: { publish: { eq: true } } }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
